@use "sass:math";

@import "@Assets/vars";
@import "@Assets/functions";
@import "@Assets/mixins";

.component {
    $p: &;

    @include desktop {
        :global([data-overlayscrollbars-contents]) {
            display: flex;
            row-gap: 24px;
            flex-direction: column;
        }
    }
}
