@use "sass:math";

@import "@Assets/vars";
@import "@Assets/functions";
@import "@Assets/mixins";

.component {
    $p: &;

    position: relative;

    // to increase specificity
    #{$p} {
        &__control {
            position: relative;
            column-gap: 10px;
            min-height: 40px;
            padding: 4px 0;
            border-bottom: 1px solid $color-alto;
            color: $color-cod-gray;
            font-weight: 400;
            font-size: 16px;
            line-height: math.div(19.2, 16);
            cursor: pointer;
            transition: border-color $transition;

            &:global(.isFocused),
            &:global(.isSelected) {
                border-color: $color-cod-gray;
            }

            @include desktop {
                &:hover {
                    border-color: $color-cod-gray;
                }
            }

            @include tablet {
                min-height: 38px;
            }

            @include tabletOnly {
                font-size: 14px;
                line-height: math.div(17, 14);
            }

            @include mobile {
                min-height: 36px;
                padding-top: 0;
                padding-bottom: 8px;
            }
        }

        &__dropdownIndicator {
            position: relative;
            margin-right: 4px;
            font-size: 15px;
            transition: transform $transition;

            path {
                stroke-width: 1.5;
            }

            &:global(.isReversed) {
                transform: scaleY(-1);
            }

            @include tablet {
                margin-right: 5px;
            }
        }

        &__menu {
            margin-top: 8px;
            background-color: $color-white;
            box-shadow: 0 4px 30px rgba($color-black, 0.15);
        }

        &__menuList {
            @include scrollContainer;

            max-height: 294px;
            padding: 12px;

            @include mobile {
                max-height: 246px;
            }
        }

        &__option {
            padding: 12px;
            color: $color-cod-gray;
            font-size: 16px;
            line-height: math.div(21, 16);
            cursor: pointer;
            transition: background-color $transition;

            &:global(.isSelected) {
                background-color: $color-wild-sand;
            }

            @include desktop {
                &:hover {
                    background-color: $color-wild-sand;
                }
            }

            @include mobile {
                padding: 8px;
            }
        }

        &__placeholder {
            color: $color-dusty-gray;
        }
    }

    &__info {
        display: none;
        position: absolute;
        top: 100%;
        right: 0;
        margin-top: 2px;
        font-size: 12px;
        line-height: math.div(14.4, 12);
        text-align: right;
        cursor: pointer;

        #{$p}._hasInfo & {
            display: block;
        }

        &._error {
            color: $color-red;
        }

        &._success {
            color: $color-green;
        }
    }
}

._hidden {
    display: hidden;
}
