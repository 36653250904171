@use "sass:math";

@import "@Assets/vars";
@import "@Assets/functions";
@import "@Assets/mixins";

.component {
    $p: &;

    display: flex;
    row-gap: vpw(12);
    flex-direction: column;
    width: vpw(302);
    padding: 0;
    font-weight: 400;
    font-size: vpw(12);
    line-height: 1;

    &:global(.Toastify__toast-container--top-left),
    &:global(.Toastify__toast-container--bottom-left) {
        right: auto;
        left: var(--container-p-x, 42px);

        @include tablet {
            left: var(--container-p-x, 16px);
        }

        @include mobile {
            right: var(--container-p-x, 16px);
        }
    }

    &:global(.Toastify__toast-container--top-right),
    &:global(.Toastify__toast-container--bottom-right) {
        right: var(--container-p-x, 42px);
        left: auto;

        @include tablet {
            right: var(--container-p-x, 16px);
        }

        @include mobile {
            left: var(--container-p-x, 16px);
        }
    }

    &:global(.Toastify__toast-container--top-left),
    &:global(.Toastify__toast-container--top-right) {
        top: var(--container-p-x, 42px);

        @include tablet {
            top: var(--container-p-x, 16px);
        }
    }

    &:global(.Toastify__toast-container--bottom-left),
    &:global(.Toastify__toast-container--bottom-right) {
        bottom: var(--container-p-x, 42px);

        @include tablet {
            bottom: var(--container-p-x, 16px);
        }
    }

    @include tablet {
        row-gap: 12px;
        width: 302px;
        font-size: 12px;
    }

    @include mobile {
        width: auto;
    }

    :global {
        .Toastify__toast {
            min-height: auto;
            margin: 0;
            font-family: $font-jost;
        }

        .Toastify__toast-theme {
            &--light {
                padding: vpw(16);
                padding-right: vpw(36);
                border-radius: 0;
                background: $color-white;
                box-shadow: 0px vpw(4) vpw(20) 0px rgba($color-black, 0.08);
                color: $color-dove-gray;
            }

            @include tablet {
                &--light {
                    padding: 16px;
                    padding-right: 36px;
                    box-shadow: 0px 4px 20px 0px rgba($color-black, 0.08);
                }
            }
        }

        .Toastify__toast-body {
            column-gap: vpw(16);
            padding: 0;

            @include tablet {
                column-gap: 16px;
            }
        }

        .Toastify__toast-icon {
            width: vpw(20);
            height: vpw(20);
            margin: 0;

            @include tablet {
                width: 20px;
                height: 20px;
            }
        }

        .Toastify__icon-type {
            color: $color-dust-storm;
        }

        .Toastify__close-button {
            display: block;
            position: absolute;
            top: vpw(8);
            right: vpw(8);
            font-size: vpw(16);
            opacity: 1;
            transition: color $transition;

            svg {
                width: 1em;
                height: 1em;

                path {
                    stroke-width: 1;
                }
            }

            &--light {
                color: $color-mine-shaft;

                @include desktop {
                    &:hover {
                        color: $color-boulder;
                    }
                }
            }

            @include tablet {
                top: 8px;
                right: 8px;
                font-size: 16px;
            }
        }

        .Toastify__progress-bar {
            height: vpw(5);

            @include tablet {
                height: 5px;
            }
        }

        .Toastify__progress-bar-theme {
            &--light {
                background: $color-dove-gray;
                opacity: 1 !important;
            }
        }
    }
}
