@use "sass:math";

@import "@Assets/vars";
@import "@Assets/mixins";

.component {
    $p: &;

    position: relative;
    padding-bottom: 40px;

    @include desktop {
        a:hover {
            color: $color-boulder;
        }
    }

    @include tablet {
        padding-bottom: 0;
    }

    &__section {
        @include gridContainer;

        padding-top: 24px;
        padding-bottom: 24px;
        border-top: 1px solid $color-alto;

        @include tablet {
            padding-top: 40px;
            padding-bottom: 40px;
        }

        @include mobile {
            display: block;
            padding-top: 16px;
            padding-bottom: 0;
        }

        &_bottom {
            display: flex;
            column-gap: var(--grid-column-gap);
            justify-content: space-between;
            padding-top: 12px;
            padding-bottom: 16px;

            @include tablet {
                padding-bottom: 12px;
            }

            @include mobile {
                display: flex;
                flex-wrap: wrap;
                padding-top: 0;
                padding-bottom: 12px;
                border-top: 0;
                color: $color-dusty-gray;
                font-size: 12px;
                line-height: math.div(18, 12);
                letter-spacing: 0;
            }
        }
    }

    &__col {
        @include desktop {
            grid-column: span 2;

            &:nth-child(1) {
                grid-column: span 3;
            }

            &:nth-child(2) {
                grid-column: 5 / span 4;
            }
        }

        @include desktopSm {
            &:nth-child(1) {
                grid-column: span 4;
            }

            &:nth-child(2) {
                grid-column: 6 / span 3;
            }
        }

        @include tabletOnly {
            &:nth-child(1) {
                display: flex;
                grid-column: span 3;
                flex-direction: column;
            }

            &:nth-child(2) {
                grid-column: 5 / span 2;
                margin-right: -12px;
            }

            &:nth-child(3) {
                grid-column: span 2 / -1;
                margin-left: -30px;
            }
        }

        @include mobile {
            border-bottom: 1px solid $color-alto;

            &:nth-child(1) {
                border-bottom: 0;
            }

            &:nth-child(2) {
                margin-top: 20px;
            }
        }
    }

    &__logo {
        display: block;
        width: 135px;
        height: 56px;
        background-color: currentColor;
        color: $color-cod-gray;
        mask-image: url("./images/logo.png");
        mask-repeat: no-repeat;
        mask-size: contain;

        @include tablet {
            width: 146px;
            height: 48px;
            mask-image: url("./images/logo-m.png");
        }

        @include mobile {
            width: 113px;
            height: 37px;
        }
    }

    &__text {
        margin-top: 24px;

        &_agreement {
            margin-top: 12px;
            color: $color-dusty-gray;
            font-size: 12px;
            line-height: math.div(14, 12);

            a {
                text-decoration: underline;
                text-decoration-skip-ink: none;
                text-underline-offset: 2px;

                @include desktop {
                    &:hover {
                        text-decoration: none;
                    }
                }

                @include tablet {
                    text-underline-offset: 1px;
                }
            }
        }

        @include tablet {
            &:not(&_agreement) {
                margin-top: 16px;
            }
        }

        @include mobile {
            &:not(&_agreement) {
                font-size: 14px;
                line-height: math.div(16, 14);
            }
        }
    }

    &__form {
        margin-top: 12px;
    }

    &__socials {
        @include tabletOnly {
            display: flex;
            column-gap: 12px;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;
            margin-top: auto;
            padding-top: 12px;
        }
    }

    &__title {
        color: $color-cod-gray;
        letter-spacing: 0;

        @include mobile {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 12px 0;
            font-size: 18px;
            line-height: math.div(22, 18);
            cursor: pointer;

            svg {
                top: -1px;
                margin: -1px 0;
                font-size: 20px;
                transition: transform $transition;
            }

            &:global(.isDropdown) {
                svg {
                    transform: scaleY(-1);
                }
            }
        }
    }

    &__linksContainer {
        @include desktop {
            display: flex;
            column-gap: var(--grid-column-gap);
        }

        @include desktopSm {
            column-gap: 16px;
            flex-direction: column;
        }
    }

    &__links {
        display: flex;
        row-gap: 16px;
        flex-direction: column;
        margin-top: 16px;

        &_bottom {
            display: flex;
            column-gap: var(--grid-column-gap);
            row-gap: 0;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            margin-top: 0;
            text-align: center;
        }

        #{$p}__linksContainer & {
            flex: 1;
        }

        @include tabletOnly {
            &:not(&_bottom) {
                row-gap: 12px;
                margin-top: 20px;

                #{$p}__linksContainer & {
                    &:not(:first-child) {
                        margin-top: 12px;
                    }
                }
            }
        }

        @include mobile {
            &:not(&_socials):not(&_bottom) {
                row-gap: 12px;
                margin-top: 0;
                padding-bottom: 12px;
                font-size: 16px;
                line-height: math.div(19.2, 16);
            }

            &_socials {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                align-items: center;
                margin-top: 0;
                padding-top: 12px;
                padding-bottom: 12px;
                gap: 20px;
                font-size: 14px;
                line-height: math.div(16, 14);
            }

            &_bottom {
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-start;
                order: -1;
                width: 100%;
                margin-bottom: 12px;
                padding-top: 12px;
                padding-bottom: 16px;
                border-bottom: 1px solid $color-alto;
                text-align: left;
            }
        }
    }

    &__team {
        @include mobile {
            width: calc(40% - var(--grid-column-gap));
        }
    }

    &__copy {
        text-align: right;

        @include mobile {
            width: calc(60% - var(--grid-column-gap));
        }
    }
}
