@use "sass:math";

@import "@Assets/vars";
@import "@Assets/mixins";

.component {
    $offsetY: 50px;

    transition:
        opacity $transition,
        transform $transition;
    transition-duration: $transition-duration * 2;

    &_fadeIn,
    &_fadeInTop,
    &_fadeInBottom {
        opacity: 0;
    }

    &_fadeInTop {
        transform: translateY(-$offsetY);
    }

    &_fadeInBottom {
        transform: translateY($offsetY);
    }

    &_animated,
    :global(.swiper-slide-duplicate) & {
        transform: none;
        opacity: 1;
    }

    @include tablet {
        transition-delay: 0s !important;
    }
}
