@use "sass:math";

@import "@Assets/vars";
@import "@Assets/functions";
@import "@Assets/mixins";

.component {
    $p: &;

    display: flex;
    row-gap: 12px;
    flex-direction: column;
    color: $color-cod-gray;

    @include tablet {
        font-weight: 400;
    }

    @include mobile {
        row-gap: 8px;
    }

    &__item {
        display: flex;
        column-gap: 20px;
        align-items: center;
        justify-content: space-between;

        &:last-child {
            padding-top: 12px;
            border-top: 1px solid $color-alto;
        }

        @include mobile {
            column-gap: 16px;

            &:last-child {
                padding-top: 8px;
                font-size: 20px;
                line-height: math.div(24, 20);
            }
        }
    }
}
