@use "sass:math";

@import "@Assets/vars";
@import "@Assets/functions";
@import "@Assets/mixins";

.component {
    $p: &;

    position: relative;
    padding: 24px 24px 12px;
    background-color: $color-white;
    color: $color-cod-gray;

    @include tablet {
        padding: 16px var(--container-p-x, 16px) 12px;
    }

    &__loader {
        display: flex;
        z-index: 1;
        position: absolute;
        top: 0;
        left: 0;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        background-color: rgba($color-white, 0.75);
    }

    &__title {
        font-size: 18px;
        line-height: 1;

        @include tablet {
            font-size: 16px;
            line-height: math.div(19, 16);
        }
    }

    &__content {
        margin-top: 12px;
        border-top: 1px solid $color-alto;

        @include mobile {
            margin-top: 16px;
        }
    }

    &__list {
        max-height: 233px;
        margin-right: -16px;
        padding-right: 16px;

        @include mobile {
            max-height: 236px;
            margin-right: -13px;
            padding-right: 13px;
        }
    }

    &__item {
        padding: 11px 0;
        border-bottom: 1px solid $color-alto;
    }

    &__messageEmpty {
        padding: 46px 0 34px;

        @include tablet {
            padding: 32px 0 16px;
        }
    }

    &__messageEmptyText {
        @include desktop {
            font-size: 20px;
            line-height: math.div(24, 20);
        }

        @include mobile {
            font-size: 18px;
            line-height: math.div(22, 18);
        }
    }

    &__messageEmptyCaption {
        max-width: 300px;

        @include desktop {
            font-size: 16px;
            line-height: math.div(19, 16);
        }

        @include mobile {
            font-size: 14px;
            line-height: math.div(17, 14);
        }
    }

    &__messageEmptyButton {
        height: 48px;
        margin-top: 32px;

        @include tablet {
            height: 46px;
        }

        @include mobile {
            width: auto;
            min-width: 240px;
            height: 40px;
        }
    }
}
