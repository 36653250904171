@use "sass:math";

@import "@Assets/vars";
@import "@Assets/functions";
@import "@Assets/mixins";

.component {
    position: relative;
    width: inherit;
    height: inherit;

    &__loader {
        display: flex;
        z-index: 1;
        position: absolute;
        top: 0;
        left: 0;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }
}

.wrapper {
    position: relative;
}

.emptyWrapper {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;

    background-color: $color-wild-sand;

    &._placeholder_white {
        background-color: $color-white;
    }
}
