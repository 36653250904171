@use "sass:math";

@import "@Assets/vars";
@import "@Assets/functions";
@import "@Assets/mixins";

.component {
    $p: &;

    &__header {
        display: flex;
        column-gap: 20px;
        align-items: center;
    }

    &__color {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 8px;

        span {
            display: block;
            color: $color-boulder;
        }

        @include tabletOnly {
            font-weight: 500;
        }

        @include mobile {
            font-size: 14px;
            line-height: math.div(18, 14);
        }
    }

    &__arrows {
        display: flex;
        column-gap: 4px;
        flex-shrink: 0;
        align-items: center;
        margin-left: auto;
    }

    &__arrow {
        @include clickableSpace(24px, 24px);

        display: block;
        flex-shrink: 0;
        color: $color-cod-gray;
        font-size: 18px;
        line-height: 1;

        path {
            stroke-width: 1.5;
        }

        &:global(.swiper-button-lock) {
            display: none;
        }
    }

    &__slider {
        margin-top: 12px;

        &:global(:not(.swiper-initialized)) {
            :global(.swiper-wrapper) {
                display: grid;
                grid-template-columns: repeat(6, 1fr);
                column-gap: 8px;
            }
        }

        @include tablet {
            &:global(:not(.swiper-initialized)) {
                :global(.swiper-wrapper) {
                    grid-template-columns: repeat(4, 1fr);
                    column-gap: 12px;
                }
            }
        }

        @include mobile {
            margin-top: 8px;

            &:global(:not(.swiper-initialized)) {
                :global(.swiper-wrapper) {
                    grid-template-columns: repeat(5, 1fr);
                }
            }
        }
    }

    &__image {
        display: block;
        position: relative;
        height: 0;
        padding-top: percentage(math.div(78, 64));
        overflow: hidden;
        background-color: $color-wild-sand;
        cursor: pointer;

        &::before {
            display: block;
            z-index: 1;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border: 1px solid $color-cod-gray;
            content: "";
            opacity: 0;
            transition: opacity $transition;
        }

        &:global(.isCurrent)::before {
            opacity: 1;
        }

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        @include desktop {
            &:hover::before {
                opacity: 0.5;
            }
        }

        @include tablet {
            padding-top: percentage(math.div(82, 67));
        }

        @include mobile {
            padding-top: percentage(math.div(75, 56));
        }
    }
}
