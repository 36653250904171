@use "sass:math";

@import "@Assets/vars";
@import "@Assets/functions";
@import "@Assets/mixins";

.component {
    $p: &;

    position: relative;
    color: $color-cod-gray;

    &__linkEdit {
        display: block;
        position: absolute;
        bottom: 100%;
        left: 0;
        margin-bottom: 10px;
        color: $color-dusty-gray;
        transition: color $transition;

        svg {
            top: 1px;
        }

        #{$p}_card & {
            position: relative;
            bottom: 0;
            margin-bottom: 24px;
        }

        @include desktop {
            &:hover {
                color: $color-cod-gray;
            }
        }

        @include tablet {
            margin-bottom: 6px;
            color: $color-dove-gray;
        }

        @include mobile {
            position: relative;
            bottom: 0;
            margin-bottom: 16px;

            #{$p}_card & {
                margin-bottom: 16px;
            }
        }
    }

    &__top {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 8px;
    }

    &__labels {
        display: flex;
        align-items: center;
        gap: 8px;

        @include desktop {
            #{$p}:not(#{$p}_card) & {
                line-height: math.div(22, 18);
            }
        }

        @include tabletOnly {
            font-weight: 400;
            line-height: 1;
        }
    }

    &__label {
        padding: 4px 12px;
        background-color: $color-mine-shaft;
        color: $color-white;

        @include desktop {
            #{$p}_card & {
                padding: 2px 8px;
            }
        }

        @include mobile {
            font-size: 12px;
            line-height: math.div(14, 12);
        }
    }

    &__code {
        color: $color-dusty-gray;
    }

    &__name {
        margin-top: 24px;

        &:first-child {
            margin-top: 0;
        }

        @include mobile {
            margin-top: 20px;
        }
    }

    &__price {
        display: flex;
        column-gap: 20px;
        align-items: center;
        margin-top: 24px;

        @include desktop {
            line-height: math.div(43, 36);
        }

        @include mobile {
            column-gap: 17px;
            margin-top: 20px;
        }
    }

    &__priceOld {
        color: $color-dusty-gray;
        font-size: 20px;
        line-height: 1;
        text-decoration: line-through;
        text-decoration-thickness: 1px;

        @include desktop {
            #{$p}_card & {
                font-size: 16px;
            }
        }

        @include mobile {
            font-size: 16px;
            line-height: math.div(21, 16);
        }
    }

    &__split {
        display: block;
        margin-top: 12px;
        color: $color-dusty-gray;
        font-size: 16px;
        line-height: 1;
        transition: color $transition;

        @include desktop {
            &:hover {
                color: $color-cod-gray;
            }
        }

        @include tablet {
            font-weight: 500;
            font-size: 14px;
        }

        @include mobile {
            margin-top: 8px;
        }

        svg {
            font-size: 12px;

            path {
                stroke-width: 1;
            }
        }
    }
}
