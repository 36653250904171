@use "sass:math";

@import "@Assets/vars";
@import "@Assets/functions";
@import "@Assets/mixins";

.component {
    $p: &;

    display: flex;
    position: relative;

    align-items: center;
    color: $color-cod-gray;
    cursor: pointer;

    &:global(.isDisabled) {
        cursor: not-allowed;
    }

    &._size_md {
        column-gap: 16px;

        @include mobile {
            column-gap: 12px;
        }
    }

    &._size_sm {
        column-gap: 8px;
    }

    &__input {
        display: none;
    }

    &__icon {
        display: flex;
        flex-shrink: 0;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
        border: 1px solid $color-alto;
        background-color: $color-white;
        transition:
            border-color $transition,
            background-color $transition;

        @include desktop {
            #{$p}:hover & {
                border-color: $color-cod-gray;
            }
        }

        svg {
            transform: scale(0);
            color: $color-cod-gray;
            font-size: 13px;
            transition: transform $transition;

            path {
                stroke-width: 1;
            }
        }

        #{$p}__input:checked ~ & {
            border-color: $color-cod-gray;

            svg {
                transform: none;
            }
        }

        #{$p}__input:disabled ~ & {
            border-color: $color-alto;
            background-color: $color-wild-sand;
        }
    }

    &__error {
        position: absolute;
        top: 100%;
        left: 0;
        margin-top: 2px;
        color: $color-red;
        font-size: 12px;
        line-height: math.div(14.4, 12);
    }
}
