@use "sass:math";

@import "@Assets/vars";
@import "@Assets/functions";
@import "@Assets/mixins";

.component {
    $p: &;

    @include tablet {
        display: block;
        padding: 0;
        background-color: $color-white;
    }

    &__container {
        display: flex;
        row-gap: 24px;
        flex-direction: column;
        padding: 46px 64px;

        @include desktop {
            width: auto;
            min-width: 802px;
            max-width: 1024px;
        }

        @include tablet {
            row-gap: 16px;
            max-width: none;
            margin: 0;
            padding: var(--container-p-x);
        }
    }

    &__title {
        flex-shrink: 0;
        color: $color-cod-gray;
    }

    &__buttons {
        display: flex;

        @include mobile {
            margin-top: 8px;
        }
    }

    &__button {
        flex: 1;
        padding-bottom: 8px;
        border-bottom: 1px solid currentColor;
        color: $color-dusty-gray;
        transition: color $transition;

        &:global(.isSelected) {
            color: $color-cod-gray;
        }

        @include mobile {
            padding-bottom: 5px;
        }
    }
}
