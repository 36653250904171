@use "sass:math";

@import "@Assets/vars";
@import "@Assets/functions";
@import "@Assets/mixins";

.component {
    $p: &;

    @include fixedFullWidth;
    @include scrollContainer;

    display: flex;
    z-index: 1005;
    top: 0;
    bottom: 0;
    align-items: flex-start;
    justify-content: flex-end;
    padding: calc(var(--header-height) + 8px) var(--container-p-x)
        var(--container-p-x);

    @include tablet {
        justify-content: center;
        padding: var(--container-p-x);
        background-color: rgba($color-black, 0.4);
    }

    &__container {
        position: relative;
        width: 100%;
        max-width: 394px;
        padding: 32px;
        background-color: $color-white;
        box-shadow: 0 4px 16px rgba(0, 0, 0, 0.08);

        @include tablet {
            margin: auto;
            padding: 36px 16px;
        }
    }

    &__buttonClose {
        @include clickableSpace;

        z-index: 1;
        position: absolute;
        top: 12px;
        right: 12px;
        color: $color-mine-shaft;
        font-size: 24px;
        transition: color $transition;

        @include desktop {
            &:hover {
                color: $color-boulder;
            }
        }
    }

    &__title {
        color: $color-cod-gray;

        @include tablet {
            font-weight: 500;
            font-size: 18px;
            line-height: math.div(21, 18);
            letter-spacing: 0;
        }
    }

    &__text {
        margin-top: 8px;
        color: $color-dove-gray;

        &_resend {
            margin-top: 36px;
        }

        @include tablet {
            &_resend {
                margin-top: 12px;
            }
        }
    }

    &__caption {
        margin-top: 20px;
        color: $color-cod-gray;
        font-size: 14px;
        line-height: math.div(17, 14);
        letter-spacing: 0.14px;

        @include tablet {
            margin-top: 16px;
            font-weight: 500;
            font-size: 12px;
            line-height: math.div(14, 12);
        }
    }

    &__inputCode {
        display: flex;
        position: relative;
        column-gap: 16px;
        margin-top: 16px;

        @include tablet {
            column-gap: 12px;
            margin-top: 14px;
        }
    }

    &__inputCodeError {
        position: absolute;
        top: 100%;
        left: 0;
        margin-top: 2px;
        color: $color-red;
        font-size: 12px;
        line-height: math.div(14.4, 12);
        cursor: pointer;
    }

    &__input {
        margin-top: 16px;

        #{$p}__inputCode & {
            flex: 1;
            margin-top: 0;

            input {
                justify-content: center;
                text-align: center;
            }
        }
    }

    &__button {
        width: 100%;
        margin-top: 32px;
    }

    &__buttonResend {
        margin: 36px auto 0;
        color: $color-dove-gray;

        @include desktop {
            padding-bottom: 0;
            line-height: math.div(19, 16);
            text-underline-offset: 2px;

            &:hover {
                color: $color-cod-gray !important;
            }
        }

        @include tablet {
            margin-top: 16px;
        }
    }

    &__not {
        display: flex;
        position: relative;
        flex-direction: column;
        align-items: center;
        width: max-content;
        margin: 16px auto 0;
        color: $color-mine-shaft;

        @include tablet {
            margin-top: 12px;
        }
    }

    &__tooltip {
        position: absolute;
        top: 50%;
        right: 100%;
        width: 409px;
        margin-right: 16px;
        padding: 14px 34px 14px 12px;
        transform: translateY(-50%);
        background-color: $color-linen;
        color: $color-cod-gray;

        &::before {
            display: block;
            position: absolute;
            top: 50%;
            left: 100%;
            width: 12px;
            height: 14px;
            margin-top: -7px;
            border: 6px solid transparent;
            border-left-color: $color-linen;
            content: "";
        }

        ol {
            padding-left: 24px;
            list-style-type: decimal;

            li {
                display: list-item;
                list-style-type: inherit;
            }
        }

        @include tablet {
            top: 100%;
            right: auto;
            left: 50%;
            width: 344px;
            max-width: calc(100vw - 16px);
            margin-top: 12px;
            margin-right: 0;
            padding-right: 24px;
            transform: translateX(-50%);

            &::before {
                top: auto;
                bottom: 100%;
                left: 50%;
                width: 14px;
                height: 12px;
                margin-top: 0;
                margin-left: -7px;
                border: 7px solid transparent;
                border-bottom-color: $color-linen;
            }

            ol {
                padding-left: 21px;
            }
        }
    }

    &__tooltipButtonClose {
        @include clickableSpace;
        z-index: 1;

        position: absolute;
        top: 8px;
        right: 8px;
        color: $color-mine-shaft;
        font-size: 20px;
        transition: color $transition;

        @include desktop {
            &:hover {
                color: $color-boulder;
            }
        }
    }
}
