@use "sass:math";

@import "@Assets/vars";
@import "@Assets/functions";
@import "@Assets/mixins";

.component {
    $p: &;

    z-index: 1000;
    align-items: flex-start;
    justify-content: flex-end;
    padding: calc(var(--header-height, 0px) + 8px) var(--container-p-x, 0px);

    @include tablet {
        padding: calc(var(--header-height, 0px) + 4px) 0;
    }

    &__container {
        max-width: 842px;
        margin: 0;
        background: none;

        @include tablet {
            max-width: none;
        }
    }

    &__field {
    }

    &__result {
        margin-top: 8px;

        @include tablet {
            margin-top: 5px;
        }
    }
}
