@use "sass:math";

@import "@Assets/vars";
@import "@Assets/functions";
@import "@Assets/mixins";

.component {
    $p: &;

    padding: 40px 0;

    @include tablet {
        display: flex;
        align-items: stretch;
        padding: 0;
        background-color: $color-white;
    }

    &__container {
        display: flex;
        max-width: 900px;
        max-height: calc(vh(100) - 80px);

        @include tablet {
            max-width: none;
            max-height: none;
            margin: 0;
            overflow: hidden;
            background: none;
        }
    }

    &__buttonClose {
        color: $color-white;

        @include desktop {
            top: -24px;
            right: -24px;

            &:hover {
                color: rgba($color-white, 0.75);
            }
        }
    }

    video,
    iframe {
        width: 100%;
        object-fit: contain;
        background-color: $color-black;
    }

    iframe {
        height: 500px;
        max-height: inherit;

        @include tablet {
            height: 100%;
        }
    }
}
