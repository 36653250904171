@use "sass:math";

:root {
    --container-p-x: 42px;
    --grid-column-gap: 12px;

    @include desktop {
        @media (min-width: 1920px) {
            --container-p-x: 60px;
        }
    }

    @include desktopSm {
        --container-p-x: 24px;
    }

    @include tablet {
        --container-p-x: 34px;
    }

    @include mobile {
        --container-p-x: 16px;
        --grid-column-gap: 8px;
    }
}

*,
*::before,
*::after {
    box-sizing: inherit;
    outline: none;
}

html {
    box-sizing: border-box;
    width: 100%;
    overscroll-behavior: none;
    scrollbar-width: none;

    &.isScrollLocked {
        overflow: hidden;
    }

    &.isLoading {
        cursor: progress;
        pointer-events: none;
    }
}

body {
    -webkit-font-smoothing: antialiased;
    text-decoration-skip: objects;
    text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    -moz-osx-font-smoothing: grayscale;
    width: 100%;
    min-height: vh(100);
    background-color: $color-white;
    color: $color-mine-shaft;
    font-size: 20px;
    line-height: math.div(24, 20);
    font-family: $font-jost;
    text-rendering: optimizeLegibility;

    .isScrollLocked & {
        overflow: hidden;
    }

    .isScrollLocked:not(.noScrollbar) & {
        padding-right: var(--scrollbar-width, 0);
        overflow: hidden;

        &::before {
            display: block;
            z-index: 1001;
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            overflow-y: scroll;
            content: "";
            pointer-events: none;
        }
    }

    @include tablet {
        font-size: 18px;
        line-height: math.div(22, 18);
    }

    @include mobile {
        line-height: 1;
    }
}

main {
    flex: 1;
}

:where(:not(#cdek-map)) {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    ul,
    ol,
    p,
    fieldset,
    figure,
    picture,
    blockquote {
        margin: 0;
        padding: 0;
        font-size: inherit;
    }

    a,
    button,
    [type="button"] {
        cursor: pointer;
    }

    a {
        color: currentColor;
        text-decoration: none;
        text-decoration-thickness: 1px;
        text-decoration-skip-ink: none;
        transition: color $transition;
    }

    button,
    [type="button"] {
        appearance: none;
        padding: 0;
        border: 0;
        border-radius: 0;
        background: none;
        box-shadow: none;
        color: inherit;
        font-weight: inherit;
        font-size: inherit;
        line-height: inherit;
        font-family: inherit;
        letter-spacing: inherit;
        text-decoration: none;
    }

    img,
    svg,
    video {
        display: block;
        position: relative;
        max-width: 100%;
    }

    img {
        width: 100%;
        height: auto;

        &[draggable="false"] {
            // stylelint-disable-next-line
            -webkit-user-drag: none;
            pointer-events: none;
        }
    }

    svg {
        fill: currentColor;
        width: 100%;
        height: 100%;
    }

    video {
        width: 100%;
        height: auto;
    }

    figure,
    picture {
        display: block;
        line-height: 0;
    }

    fieldset {
        border: 0;
    }

    ul,
    ol,
    li {
        display: block;
        list-style: none;
    }

    iframe {
        display: block;
    }
}

:where(#cdek-map) {
    main,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    ul,
    ol,
    p,
    fieldset,
    figure,
    picture,
    blockquote,
    a,
    button,
    [type="button"],
    // svg,
    img,
    video,
    fieldset,
    ul,
    ol,
    li,
    iframe {
        all: unset;
    }

    svg {
        fill: transparent;
        display: unset;
        position: unset;
        width: unset;
        max-width: unset;
        height: unset;
    }
}

#__next {
    display: flex;
    position: relative;
    flex-direction: column;
    min-height: vh(100);
}
