@use "sass:math";

@import "@Assets/vars";
@import "@Assets/functions";
@import "@Assets/mixins";

.component {
    $p: &;
    display: flex;
    position: relative;
    column-gap: 16px;

    &__image {
        position: relative;
        flex-shrink: 0;
        width: 71px;
        height: 109px;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        @include mobile {
            width: 83px;
            height: 111px;
        }
    }

    &__remove {
        display: flex;
        position: absolute;
        top: 0;
        right: 0;
        align-items: center;
        justify-content: center;
        font-size: 24px;
    }

    &__info {
        display: flex;
        row-gap: 12px;
        flex-grow: 1;
        flex-direction: column;

        @include mobile {
            row-gap: 16px;
        }
    }

    &__name {
        max-width: 80%;
        color: $color-cod-gray;
        letter-spacing: 0;

        @include mobile {
            font-size: 18px;
            line-height: math.div(22, 18);
        }
    }

    &__main {
        display: flex;
        flex-grow: 1;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 12px;
    }

    &__list {
        display: flex;
        row-gap: 8px;
        flex-direction: column;
        margin-top: auto;
        color: $color-dusty-gray;

        @include tablet {
            font-weight: 400;
        }

        @include mobile {
            row-gap: 4px;
        }
    }

    &__item {
        display: flex;
        column-gap: 4px;
    }

    &__price {
        flex-shrink: 0;
        align-self: flex-end;
        color: $color-cod-gray;
    }
}
