@use "sass:math";

@import "@Assets/vars";
@import "@Assets/functions";
@import "@Assets/mixins";

.component {
    $p: &;

    &__header {
        display: flex;
        column-gap: 20px;
        align-items: center;
        justify-content: space-between;
        color: $color-boulder;

        @include tabletOnly {
            font-weight: 500;
        }

        @include mobile {
            font-size: 14px;
            line-height: math.div(18, 14);
        }

        a,
        button {
            color: $color-mine-shaft;
            transition: color $transition;

            @include desktop {
                &:hover {
                    color: $color-cod-gray;
                }
            }
        }
    }

    &__sizeLabel {
        transition: color $transition;

        &:global(.isError) {
            color: $color-red;
        }
    }

    &__dropdown {
        z-index: 1;
        position: relative;
        margin-top: 12px;
        color: $color-cod-gray;

        @include mobile {
            margin-top: 8px;
        }
    }

    &__dropdownButton {
        display: block;
        padding: 15px;
        border: 1px solid $color-dusty-gray;
        background-color: $color-white;

        &_notSelected {
            span > span {
                color: $color-dusty-gray;
                text-transform: none;
            }
        }

        @include tablet {
            padding: 11px;
        }

        @include mobile {
            padding: 14px;
        }
    }

    &__dropdownIcon {
        margin-left: auto !important;
        font-size: 18px;
    }

    &__dropdownList {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        margin-top: -1px;

        &::before {
            display: block;
            z-index: 1;
            position: absolute;
            top: 0;
            right: 15px;
            left: 15px;
            height: 1px;
            background-color: $color-dusty-gray;
            content: "";

            @include tablet {
                right: 11px;
                left: 11px;
            }

            @include mobile {
                right: 14px;
                left: 14px;
            }
        }
    }

    &__dropdownListContent {
        @include scrollContainer;

        position: relative;
        max-height: 200px;
        padding: 9px 0;
        border: 1px solid $color-dusty-gray;
        border-top: 0;
        background-color: $color-white;

        @include mobile {
            padding: 10px 0;
        }
    }

    &__item {
        display: grid;
        grid-template-columns: minmax(28px, max-content) 1fr;
        column-gap: 20px;
        align-items: center;
        width: 100%;
        padding: 6px 15px;
        text-align: left;
        transition:
            color $transition,
            background-color $transition;

        @include desktop {
            &:hover {
                background-color: $color-wild-sand;
            }
        }

        @include tablet {
            grid-template-columns: minmax(20px, max-content) 1fr;
            padding: 6px 11px;
        }

        @include mobile {
            column-gap: 12px;
            padding: 8px 14px;
        }

        &:global(.isCurrent) {
            background-color: $color-wild-sand;
        }

        &:global(.isDisabled) {
            color: $color-dusty-gray;
            pointer-events: none;
        }
    }

    &__itemCaption {
        display: block;
        margin-top: 2px;
        color: $color-dusty-gray;

        @include tablet {
            margin-top: 0;
        }

        @include mobile {
            font-size: 14px;
            line-height: math.div(16, 14);
        }
    }
}
