@use "sass:math";

@import "@Assets/vars";
@import "@Assets/functions";
@import "@Assets/mixins";

.component {
    $p: &;

    @include scrollContainer;

    display: flex;
    z-index: 1005;
    position: fixed;
    top: 0;
    left: 0;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 20px;
    background-color: rgba($color-black, 0.4);

    @include tablet {
        padding: var(--container-p-x);
    }

    &__overlay {
        display: block;
        z-index: -1;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    &__container {
        position: relative;
        width: 100%;
        max-width: 840px;
        margin: auto;
        background-color: $color-white;
    }

    &__buttonClose {
        @include clickableSpace;
        z-index: 1;

        position: absolute;
        top: 12px;
        right: 12px;
        font-size: 24px;
        transition: color $transition;

        @include desktop {
            &:hover {
                color: $color-boulder;
            }
        }

        @include tablet {
            top: 16px;
            right: 16px;
        }
    }
}
