@use "sass:math";

@import "@Assets/vars";
@import "@Assets/functions";
@import "@Assets/mixins";

.component {
    $p: &;

    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border: 1px solid $color-alto;
    color: $color-tundora;
    font-size: 16px;
    transition:
        border-color $transition,
        color $transition,
        background-color $transition;

    @include desktop {
        &:hover {
            border-color: $color-cod-gray;
            background-color: $color-cod-gray;
            color: $color-white;
        }
    }

    @include tablet {
        width: 28px;
        height: 28px;
    }
}
