@use "sass:math";

.forDesktop {
    @include tablet {
        display: none !important;
    }
}

.forTablet {
    @include desktop {
        display: none !important;
    }

    @include mobile {
        display: none !important;
    }
}

.forMobile {
    @include desktop {
        display: none !important;
    }

    @include tabletOnly {
        display: none !important;
    }
}

.notForDesktop {
    @include desktop {
        display: none !important;
    }
}

.notForTablet {
    @include tabletOnly {
        display: none !important;
    }
}

.notForMobile {
    @include mobile {
        display: none !important;
    }
}

.isHidden {
    display: none !important;
}

.text {
    $p: &;

    &_inter {
        font-family: $font-inter;
    }

    &_jost {
        font-family: $font-jost;
    }

    &_h1,
    &_h2 {
        font-weight: 500;

        @include tabletOnly {
            font-weight: 600;
        }
    }

    &_h3,
    &_h4,
    &_h5,
    &_h6 {
        font-weight: 400;

        @include tabletOnly {
            font-weight: 500;
        }
    }

    &_h1 {
        font-size: 104px;
        line-height: math.div(125, 104);
        letter-spacing: 0.208px;

        @include tablet {
            font-size: 48px;
            line-height: math.div(58, 48);
            letter-spacing: 0;
        }

        @include mobile {
            font-size: 40px;
            line-height: math.div(44, 40);
            letter-spacing: 0.24px;
        }
    }

    &_h2 {
        font-size: 48px;
        line-height: math.div(58, 48);

        @include tablet {
            font-size: 32px;
            line-height: math.div(38, 32);
        }

        @include mobile {
            font-size: 28px;
            line-height: math.div(36, 28);
            letter-spacing: 0.168px;
        }
    }

    &_h3 {
        font-size: 36px;
        line-height: 1;
        letter-spacing: 0.216px;

        @include tablet {
            font-size: 24px;
            letter-spacing: 0;
        }

        @include mobile {
            font-size: 20px;
            line-height: math.div(24, 20);
            letter-spacing: 0.2px;
        }
    }

    &_h4 {
        font-size: 24px;
        line-height: math.div(26, 24);
        letter-spacing: 0.144px;

        @include tablet {
            font-size: 20px;
            line-height: math.div(22, 20);
            letter-spacing: 0;
        }

        @include mobile {
            font-size: 18px;
            line-height: math.div(22, 18);
            letter-spacing: 0;
        }
    }

    &_h5 {
        font-size: 20px;
        line-height: math.div(24, 20);
        letter-spacing: 0.12px;

        @include tablet {
            font-size: 18px;
            line-height: math.div(22, 18);
            letter-spacing: 0;
        }

        @include mobile {
            font-size: 16px;
            line-height: math.div(19, 16);
            letter-spacing: 0.096px;
        }
    }

    &_h6 {
        font-size: 14px;
        line-height: math.div(17, 14);
        letter-spacing: 0.14px;

        @include tablet {
            font-size: 12px;
            line-height: math.div(14, 12);
            letter-spacing: 0;
        }
    }

    &_p1 {
        font-size: 24px;
        line-height: math.div(29, 24);

        @include tablet {
            font-size: 18px;
            line-height: math.div(22, 18);
        }

        @include mobile {
            font-size: 14px;
            line-height: math.div(18, 14);
        }
    }

    &_p2 {
        font-size: 20px;
        line-height: math.div(24, 20);

        @include tablet {
            font-size: 18px;
            line-height: math.div(22, 18);
        }

        @include mobile {
            line-height: 1;
        }
    }

    &_p3 {
        font-size: 18px;
        line-height: 1;

        @include tablet {
            font-size: 16px;
        }

        @include mobile {
            line-height: math.div(18, 16);
        }
    }

    &_p4 {
        font-size: 16px;
        line-height: math.div(19, 16);

        @include tablet {
            font-size: 14px;
            line-height: math.div(17, 14);
        }
    }

    &_p5 {
        font-size: 14px;
        line-height: math.div(16, 14);

        @include tablet {
            font-size: 12px;
            line-height: math.div(14, 12);
        }

        @include mobile {
            letter-spacing: 0.072px;
        }
    }

    &_extraBold {
        font-weight: 800;
    }

    &_bold {
        font-weight: 700;
    }

    &_semiBold {
        font-weight: 600;
    }

    &_medium {
        font-weight: 500;
    }

    &_regular {
        font-weight: 400;
    }

    &_light {
        font-weight: 300;
    }

    &_normalcase {
        text-transform: none;
    }

    &_uppercase {
        text-transform: uppercase;
    }

    &_lowercase {
        text-transform: lowercase;
    }

    &_capitalize {
        text-transform: capitalize;
    }

    &_left {
        text-align: left;
    }

    &_right {
        text-align: right;
    }

    &_center {
        text-align: center;
    }

    &_justify {
        text-align: justify;
    }
}

.link {
    display: block;
    position: relative;
    padding-bottom: 5px;
    font-weight: 400;
    font-size: 16px;
    line-height: 1;
    text-decoration: underline;
    text-decoration-thickness: 1px;
    text-decoration-skip-ink: none;
    text-underline-offset: 5px;

    @include tablet {
        font-size: 14px;
    }

    @include tabletOnly {
        font-weight: 500;
    }
}

.loader {
    position: relative;
    width: 1em;
    height: 1em;
    overflow: hidden;
    transform: translateZ(0);
    border-radius: 50%;
    color: currentColor;
    font-size: 45px;
    text-indent: -9999em;
    animation:
        loaderSpin 1.5s infinite ease,
        loaderRound 1.5s infinite ease;

    @keyframes loaderSpin {
        0% {
            box-shadow:
                0 -0.83em 0 -0.4em,
                0 -0.83em 0 -0.42em,
                0 -0.83em 0 -0.44em,
                0 -0.83em 0 -0.46em,
                0 -0.83em 0 -0.477em;
        }
        5%,
        95% {
            box-shadow:
                0 -0.83em 0 -0.4em,
                0 -0.83em 0 -0.42em,
                0 -0.83em 0 -0.44em,
                0 -0.83em 0 -0.46em,
                0 -0.83em 0 -0.477em;
        }
        10%,
        59% {
            box-shadow:
                0 -0.83em 0 -0.4em,
                -0.087em -0.825em 0 -0.42em,
                -0.173em -0.812em 0 -0.44em,
                -0.256em -0.789em 0 -0.46em,
                -0.297em -0.775em 0 -0.477em;
        }
        20% {
            box-shadow:
                0 -0.83em 0 -0.4em,
                -0.338em -0.758em 0 -0.42em,
                -0.555em -0.617em 0 -0.44em,
                -0.671em -0.488em 0 -0.46em,
                -0.749em -0.34em 0 -0.477em;
        }
        38% {
            box-shadow:
                0 -0.83em 0 -0.4em,
                -0.377em -0.74em 0 -0.42em,
                -0.645em -0.522em 0 -0.44em,
                -0.775em -0.297em 0 -0.46em,
                -0.82em -0.09em 0 -0.477em;
        }
        100% {
            box-shadow:
                0 -0.83em 0 -0.4em,
                0 -0.83em 0 -0.42em,
                0 -0.83em 0 -0.44em,
                0 -0.83em 0 -0.46em,
                0 -0.83em 0 -0.477em;
        }
    }

    @keyframes loaderRound {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
}
