@import "@Assets/vars";
@import "@Assets/mixins";

.component {
    padding-right: var(--container-p-x);
    padding-left: var(--container-p-x);

    &_grid {
        @include gridContainer;
    }

    @include tablet {
        max-width: none;
    }
}
