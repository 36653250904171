.extra-overlay.ReactModal__Overlay {
    z-index: 9002;
    position: fixed;
    /* magic mobile viewport iOS bug fix */
    /* also see: https://css-tricks.com/css-fix-for-100vh-in-mobile-webkit/ */
    /* also see: https://allthingssmitty.com/2020/05/11/css-fix-for-100vh-in-mobile-webkit/ */
    min-height: 100vh;
    min-height: stretch;
    inset: 0;
    overflow-y: scroll;
    background-color: rgba(0, 0, 0, 0);
    transition: background-color 150ms linear;

    &--after-open {
        background-color: rgba(0, 0, 0, 0.7);
    }

    &--before-close {
        background-color: rgba(0, 0, 0, 0);
    }
}

.ReactModal__Content {
    outline: none;
}

.ReactModal__Html--open {
    overflow: hidden;
}

// TODO добавлять программно padding равный размеру скролла
.ReactModal__Body--open,
.x-image-gallery-fullscreen-body__open {
    padding-right: var(--scrollbar-width);
    overflow: hidden !important;
}

.ReactModal__Body--open {
    #__next {
        overflow: hidden;
    }
}

.extra.ReactModal__Content {
    -webkit-overflow-scrolling: touch;
    display: flex;
    align-items: center;
    //position: absolute;
    width: calc(100% - 40px);
    min-height: calc(100% - 40px);
    margin: 20px auto;
    transform: translateY(-30%);
    border: 0;
    outline: none;
    //left: 50%;
    //top: 50%;
    //transform: translate(-50%, -50%);
    background: transparent;
    opacity: 0;
    pointer-events: none; // сейчас Content используется для центрирования и поэтому он больше чем модалка и накладывается на Overflow
    transition:
        transform 150ms ease-in-out,
        opacity 150ms ease-in-out;

    &--after-open {
        transform: none;
        opacity: 1;
    }
    &--before-close {
        transform: translateY(-30%);
        opacity: 0;
    }
}

// TODO перенести в css module галереи через global
.x-image-gallery-fullscreen-body__open {
    background: #000;
}

.x-image-gallery-fullscreen-wrapper__open {
    overflow: hidden;
}
