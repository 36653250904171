@use "sass:math";

@import "@Assets/vars";
@import "@Assets/functions";
@import "@Assets/mixins";

.component {
    $p: &;

    @include tablet {
        display: block;
        padding: 0;
        background-color: $color-white;
    }

    &__container {
        display: flex;
        row-gap: 32px;
        flex-direction: column;
        max-width: 500px;
        padding: 32px;

        @include tablet {
            row-gap: var(--container-p-x);
            max-width: none;
            max-height: none;
            margin: 0;
            padding: var(--container-p-x);
            background: none;
        }
    }

    &__title {
        flex-shrink: 0;
        color: $color-cod-gray;

        @include desktop {
            font-size: 18px;
            line-height: math.div(22, 18);
        }

        @include mobile {
            padding-right: 40px;
        }
    }

    &__subtitle {
        margin-top: -24px;
        color: $color-boulder;

        @include tablet {
            margin-top: calc(var(--container-p-x) / -2);
        }
    }

    &__content {
        margin-top: -16px;

        @include tablet {
            margin-top: calc(var(--container-p-x) / -2);
        }
    }
}
