@use "sass:math";

@import "@Assets/vars";
@import "@Assets/functions";
@import "@Assets/mixins";

.component {
    $p: &;

    display: flex;
    flex-wrap: wrap;
    gap: 12px;

    @include mobile {
        gap: 8px;
    }

    &__buttonCartWrapper {
        width: 100%;
    }

    &__buttonCart {
        width: 100%;

        @include mobile {
            height: 48px;
        }
    }

    &__buttonOneClick {
        flex-grow: 1;

        @include mobile {
            width: auto;
            height: 48px;
        }
    }

    &__buttonFavorites {
        display: flex;
        flex-shrink: 0;
        align-items: center;
        justify-content: center;
        width: 56px;
        height: 56px;
        border: 1px solid $color-dusty-gray;
        background-color: $color-white;
        color: $color-cod-gray;
        font-size: 24px;
        line-height: 1;
        transition: border-color $transition;

        @include desktop {
            &:hover {
                border-color: $color-cod-gray;
            }
        }

        @include tablet {
            width: 46px;
            height: 46px;
            font-size: 20px;
        }

        @include mobile {
            width: 48px;
            height: 48px;
            font-size: 24px;
        }

        &:global(.inFavorites) svg path {
            fill: $color-cod-gray;
        }
    }
}
