@use "sass:math";

@import "@Assets/vars";
@import "@Assets/functions";
@import "@Assets/mixins";

.component {
    $p: &;

    @include tablet {
        margin: auto;
    }

    &__container {
        display: flex;
        position: relative;
        row-gap: 16px;
        flex-direction: column;
        width: 100%;
        max-width: 456px;
        padding: 16px;
        background-color: $color-white;

        @include tablet {
            margin: auto;
        }
    }

    &__buttonClose {
        @include clickableSpace;

        z-index: 1;
        position: absolute;
        top: 16px;
        right: 16px;
        color: $color-mine-shaft;
        font-size: 24px;
        transition: color $transition;

        @include desktop {
            &:hover {
                color: $color-boulder;
            }
        }

        @include mobile {
            top: 8px;
            right: 8px;
        }
    }

    &__title {
        color: $color-cod-gray;

        @include tablet {
            font-size: 18px;
            line-height: math.div(21, 18);
            letter-spacing: 0;
        }
    }

    &__products {
        max-height: 202px;
        padding-right: 18px;

        @include mobile {
            margin-top: -4px;
            margin-right: -12px;
            padding-right: 14px;
        }
    }

    &__product {
        padding: 8px 0;
        border-bottom: 1px solid $color-alto;

        &:first-child {
            padding-top: 0;
        }

        &:last-child {
            padding-bottom: 0;
            border-bottom: 0;
        }
    }

    &__info {
        display: flex;
        row-gap: 12px;
        flex-direction: column;
        color: $color-cod-gray;

        @include mobile {
            font-size: 16px;
            line-height: math.div(19, 16);
        }
    }

    &__infoItem {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &:last-child {
            padding-top: 12px;
            border-top: 1px solid $color-alto;
        }

        @include mobile {
            &:last-child {
                font-size: 20px;
                line-height: math.div(24, 20);
            }
        }
    }

    &__buttonCheckout {
        width: 100%;
        margin-top: 8px;

        @include mobile {
            height: 48px;
        }
    }

    &__linkCart {
        align-self: center;
        color: $color-mine-shaft;
    }

    &__linkCartIcon {
        font-size: 12px;

        path {
            stroke-width: 1;
        }
    }
}
