.component {
    display: block;
    width: 1em;
    height: 1em;
    font-size: 1em;

    path,
    circle {
        vector-effect: non-scaling-stroke;
    }
}
