@use "sass:math";

@import "@Assets/vars";
@import "@Assets/functions";
@import "@Assets/mixins";

.component {
    $p: &;

    position: relative;
    background-color: $color-black;
    cursor: pointer;

    &__iconPlay {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -43px;
        margin-left: -43px;
        color: $color-white;
        font-size: 86px;
        pointer-events: none;
        transition: transform $transition;

        #{$p}:global(.isPlaying) & {
            display: none;
        }

        @include desktop {
            #{$p}:hover & {
                transform: scale(1.05);
            }
        }

        @include tablet {
            margin-top: -30px;
            margin-left: -30px;
            font-size: 60px;
        }

        @include mobile {
            margin-top: -24px;
            margin-left: -24px;
            font-size: 48px;
        }
    }
}
