@use "sass:math";

@import "@Assets/vars";
@import "@Assets/mixins";

.component {
    $p: &;

    position: relative;
    width: 100%;
    overflow: hidden;

    &__image {
        position: relative;
        height: 0;
        padding-top: percentage(math.div(502, 330));
        overflow: hidden;
        background-color: $color-wild-sand;

        @include tablet {
            padding-top: percentage(math.div(338, 225));
        }

        @include mobile {
            padding-top: percentage(math.div(369, 256));
        }

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: transform $transition;

            @include desktop {
                #{$p}:hover & {
                    transform: scale(math.div(339, 330));
                }
            }
        }
    }

    &__text {
        margin-top: 12px;
        color: $color-cod-gray;
    }

    &__link {
        display: block;
        z-index: 2;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
    }
}
