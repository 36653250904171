@keyframes fadeInUp {
    from {
        transform: translate3d(0, 50%, 0);
        opacity: 0;
    }

    to {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

.fadeInUp {
    animation-name: fadeInUp;
}

@keyframes slideUp {
    from {
        transform: translate3d(0, 0%, 0);
    }

    to {
        transform: translate3d(0, -100%, 0);
    }
}

.slideUp {
    transform: translate3d(0, -100%, 0);
    transition: transform 1.5s ease;
    transition-delay: 500ms;
    will-change: auto;
}
