@use "sass:math";

@import "@Assets/vars";
@import "@Assets/functions";
@import "@Assets/mixins";

.component {
    $p: &;

    &__container {
        display: flex;
        max-width: 763px;
        min-height: 239px;
        background-color: $color-wild-sand;

        @include mobile {
            display: block;
            max-width: 400px;
            min-height: auto;
        }
    }

    &__buttonClose {
        @include mobile {
            top: 12px;
            right: 12px;
        }
    }

    &__image {
        position: relative;
        flex-shrink: 0;
        width: 302px;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        @include mobile {
            width: 100%;
            height: 160px;
        }
    }

    &__content {
        display: flex;
        row-gap: 12px;
        flex-grow: 1;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 32px;
        color: $color-cod-gray;

        @include mobile {
            padding: 40px 12px 34px;
        }
    }

    &__text {
        letter-spacing: 0;

        @include mobile {
            font-size: 18px;
            line-height: math.div(22, 18);
        }
    }

    &__actions {
        margin-top: 20px;
    }
}
