@use "sass:math";

@import "@Assets/vars";
@import "@Assets/functions";
@import "@Assets/mixins";

.component {
    $p: &;

    display: flex;
    column-gap: 8px;
    align-items: flex-start;

    @include mobile {
        display: block;
    }

    &__text {
        width: 100%;
        max-width: 502px;

        h3 {
            margin-top: 16px;
            font-weight: 400;
            font-size: 18px;
            line-height: math.div(22, 18);

            &:first-child {
                margin-top: 0;
            }

            @include tablet {
                font-size: 16px;
            }

            @include mobile {
                line-height: math.div(18, 16);
            }
        }

        p {
            margin-top: 16px;
            font-size: 14px;
            line-height: math.div(16, 14);

            &:first-child {
                margin-top: 0;
            }

            @include tablet {
                font-size: 14px;
                line-height: math.div(17, 14);
            }
        }

        h3 + p {
            margin-top: 4px;
        }
    }

    &__image {
        flex-shrink: 0;
        width: 100%;
        max-width: 161px;
        margin: 0 auto;

        @include mobile {
            max-width: 151px;
            margin-top: 24px;
        }
    }
}
