@use "sass:math";

@import "@Assets/vars";
@import "@Assets/functions";
@import "@Assets/mixins";

.component {
    $p: &;

    &__text {
        color: $color-cod-gray;

        @include desktop {
            line-height: math.div(22, 18);
        }
    }

    &__form {
        display: flex;
        row-gap: 16px;
        flex-direction: column;
        margin-top: 16px;

        @include mobile {
            row-gap: 12px;
            margin-top: 10px;
        }
    }

    &__policyText {
        color: $color-dusty-gray;
        font-size: 12px;
        line-height: math.div(14, 12);

        a {
            text-decoration: underline;
            text-underline-offset: 1px;

            @include desktop {
                &:hover {
                    color: $color-cod-gray;
                }
            }
        }
    }

    &__button {
        margin-top: 16px;

        @include mobile {
            margin-top: 12px;
        }
    }
}
