@use "sass:math";

@import "@Assets/vars";
@import "@Assets/functions";
@import "@Assets/mixins";

.component {
    $p: &;

    @include fixedFullWidth;

    display: block;
    z-index: 1001;
    top: 0;
    color: $color-cod-gray;
    transition: transform $transition;

    &_hidden {
        transform: translateY(-100%);
    }

    &_noClick {
        pointer-events: none;
    }

    @include desktop {
        a:not([class*="Button_component"]),
        button:not([class*="Button_component"]) {
            transition: color $transition;
        }

        a:not([class*="Button_component"]):hover,
        button:not([class*="Button_component"]):hover {
            color: $color-boulder;
        }
    }

    &__cartContainer {
        @include fixedFullWidth;
        @include scrollContainer;

        display: flex;
        z-index: 1010;
        left: auto;
        align-items: flex-start;
        justify-content: flex-end;
        transform: translateY(calc(var(--header-height) + 8px))
            translateX(calc(var(--container-p-x) * -1));
        box-shadow: 0 4px 30px rgba($color-black, 0.15);
        transition: transform $transition;

        &._header_hidden {
            transform: translateY(8px)
                translateX(calc(var(--container-p-x) * -1));
        }

        @include tablet {
            top: 0;
            bottom: 0;
            left: 0;
            padding: var(--container-p-x);
            transform: none;
            background-color: rgba($color-black, 0.4);

            &._header_hidden {
                transform: none;
            }
        }
    }

    &__top {
        z-index: 1;
        position: relative;
        padding: 3px 0 4px;
        background-color: $color-cod-gray;
        color: $color-white;

        @include tablet {
            padding: 6px 0;
            line-height: 1;
        }
    }

    &__main {
        z-index: 1;
        position: relative;
        overflow: hidden;
        background-color: $color-white;
        transition: background-color $transition;

        #{$p}_light:not(:hover) & {
            background-color: transparent;
        }
    }

    &__mainContainer {
        display: flex;
        position: relative;
        align-items: center;
        padding-top: 12px;
        padding-bottom: 12px;

        &::before {
            display: block;
            position: absolute;
            right: var(--container-p-x);
            bottom: 0;
            left: var(--container-p-x);
            height: 1px;
            background-color: $color-alto;
            content: "";
            transition: opacity $transition;
        }

        #{$p}_light:not(:hover) & {
            color: $color-white;

            &::before {
                opacity: 0;
            }
        }

        @include tablet {
            column-gap: 10px;
        }

        @include mobile {
            column-gap: 17px;
            padding-top: 8px;
            padding-bottom: 9px;
        }
    }

    &__burger {
        font-size: 24px;
        transition: color $transition;
    }

    &__logo {
        display: block;
        flex-shrink: 0;
        width: 147px;
        height: 48px;
        background-color: currentColor;
        mask-image: url("./images/logo.png");
        mask-repeat: no-repeat;
        mask-size: contain;

        @include desktopSm {
            width: 110px;
            height: 36px;
        }

        @include tablet {
            width: 146px;
        }

        @include mobile {
            width: 111px;
            height: 37px;
        }
    }

    &__nav {
        display: flex;
        column-gap: 32px;
        align-self: stretch;
        margin: -20px 20px -20px 56px;
        padding-top: 1px;

        @include desktopSm {
            column-gap: 20px;
            margin-left: 20px;
        }
    }

    &__navLink {
        display: flex;
        position: relative;
        align-items: center;
        transition: color $transition;

        &::before {
            display: block;
            position: absolute;
            top: 0;
            right: -16px;
            bottom: 0;
            left: -16px;
            content: "";

            @include desktopSm {
                right: -10px;
                left: -10px;
            }
        }

        &:global(.isActive) {
            color: $color-boulder;
        }
    }

    &__controls {
        display: flex;
        column-gap: 24px;
        align-items: center;
        margin-left: auto;
        font-size: 24px;
        line-height: 1;

        @include desktopSm {
            column-gap: 20px;
        }

        @include mobile {
            column-gap: 16px;
        }
    }

    &__controlsButton {
        display: block;
        position: relative;
        flex-shrink: 0;
        transition: color $transition;

        &::before {
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            width: 40px;
            height: 40px;
            margin-top: -20px;
            margin-left: -20px;
            content: "";
        }

        &:global(.isActive) {
            color: $color-boulder;
        }
    }

    &__controlsCounter {
        display: flex;
        z-index: 1;
        position: absolute;
        top: -6px;
        left: 100%;
        align-items: center;
        justify-content: center;
        min-width: 16px;
        height: 16px;
        margin-left: -2px;
        padding: 0 4px;
        transform: translateX(-50%);
        border-radius: 42px;
        background-color: $color-cod-gray;
        color: $color-white;
        font-size: 12px;
        line-height: 1;

        @include tablet {
            margin-left: -4px;
        }
    }

    &__dropdowns {
        @include scrollContainer;

        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        max-height: calc(#{vh(100)} - var(--header-height));
        padding: 32px 0;
        background-color: $color-white;
        box-shadow: 0 4px 30px 0px rgba($color-black, 0.15);
        color: $color-cod-gray;
    }
}
