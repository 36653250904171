@use "sass:math";

@import "@Assets/vars";
@import "@Assets/functions";
@import "@Assets/mixins";

.component {
    $p: &;

    @include scrollContainer;

    z-index: 1001;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 16px 0;
    background-color: $color-white;
    color: $color-cod-gray;

    &__container {
        display: flex;
        row-gap: 24px;
        flex-direction: column;
        min-height: 100%;
    }

    &__close {
        @include clickableSpace;

        z-index: 1;
        position: absolute;
        top: 16px;
        right: 16px;
        color: $color-mine-shaft;
        font-size: 24px;

        &::before {
            border-radius: 50%;
            background-color: $color-white;
        }
    }

    &__top {
        @include scrollContainer(x);

        display: flex;
        column-gap: 32px;
        align-items: center;
        margin: -10px -16px;
        padding: 10px 56px 10px 16px;
        color: $color-boulder;
    }

    &__topLink {
        display: block;
        flex-shrink: 0;
        padding-bottom: 4px;
        white-space: nowrap;
        transition: color $transition;

        &:global(.isCurrent) {
            color: $color-cod-gray;
            text-decoration: underline;
            text-decoration-thickness: 1px;
            text-decoration-skip-ink: none;
            text-underline-offset: 8px;
        }

        @include mobile {
            font-size: 18px;
            line-height: math.div(22, 18);
        }
    }

    &__dropdown {
        display: flex;
        flex-direction: column;
    }

    &__dropdownIcon {
        font-size: 14px;

        path {
            stroke-width: 1;
        }

        #{$p}__link_lv1 & {
            font-size: 8px;
        }
    }

    &__link {
        display: block;
        width: auto;
        margin: 0 calc(var(--container-p-x) * -1);
        padding: 10px var(--container-p-x);
        transition: background-color $transition;

        &_lv1,
        &_lv2 {
            padding-top: 8px;
            padding-bottom: 8px;
        }

        &_lv2 {
            font-size: 14px;
            line-height: math.div(16, 14);
        }

        &:global(.isCurrent) {
            background-color: $color-wild-sand;
        }

        @include mobile {
            &:not(&_lv2) {
                font-size: 18px;
                line-height: math.div(22, 18);
            }
        }
    }

    &__socials {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-top: auto;
        gap: 12px 32px;
        color: $color-mine-shaft;
        letter-spacing: 0;

        @include mobile {
            font-size: 18px;
            line-height: math.div(22, 18);
        }
    }
}
