@use "sass:math";

@import "@Assets/vars";
@import "@Assets/functions";
@import "@Assets/mixins";

.component {
    $p: &;

    &__button {
        width: 100%;
    }

    &__icon {
        transition: transform $transition;

        &_reversed {
            transform: scaleY(-1);
        }
    }
}
