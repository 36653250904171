@use "sass:math";

@import "@Assets/vars";
@import "@Assets/mixins";

.component {
    $p: &;

    :global(.swiper-wrapper) {
        box-sizing: inherit;
    }

    &:global(.swiper-css-mode) :global(.swiper-wrapper) {
        overflow-y: hidden;
    }
}
