@use "sass:math";

@import "@Assets/vars";
@import "@Assets/functions";
@import "@Assets/mixins";

.component {
    $p: &;

    @include desktop {
        justify-content: center;
        padding: 0;
        overflow: hidden;
    }

    &__container {
        @include desktop {
            display: flex;
            flex-direction: column;
            align-items: center;
            max-width: 964px;
            max-height: calc(100% - 40px);
            padding: 32px;
            overflow: hidden;
            background-color: $color-white;

            &:global(.isLoading) {
                height: 656px;
            }
        }
    }

    &__loader {
        @include desktop {
            display: flex;
            position: absolute;
            top: 0;
            left: 0;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            color: $color-cod-gray;
        }
    }

    &__error {
        @include desktop {
            display: flex;
            align-items: center;
            justify-content: center;
            min-height: 100%;
        }
    }

    &__body {
        @include desktop {
            display: flex;
            column-gap: 32px;
            flex-grow: 1;
            align-self: stretch;
            width: auto;
            margin: 0 -14px;
            padding: 0 14px;
            overflow: hidden;
        }
    }

    &__gallery {
        @include desktop {
            flex-shrink: 0;
            width: 444px;
        }
    }

    &__content {
        @include desktop {
            margin-right: -14px;
            padding-right: 14px;
        }
    }

    &__link {
        @include desktop {
            display: block;
            flex-shrink: 0;
            margin-top: 32px;
            margin-bottom: 4px;

            &:hover {
                color: $color-boulder;
            }
        }
    }

    &__linkIcon {
        path {
            stroke-width: 1;
        }

        @include desktop {
            font-size: 12px;
        }
    }
}
