.component {
    $p: &;

    display: flex;
    align-items: center;

    &__icon {
        display: flex;
        flex-shrink: 0;
        align-items: center;
        margin-right: 4px;

        #{$p}--right & {
            order: 1;
            margin-right: 0;
            margin-left: 4px;
        }
    }
}
