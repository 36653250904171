@use "sass:math";

@import "@Assets/vars";
@import "@Assets/functions";
@import "@Assets/mixins";

.component {
    $p: &;
    z-index: 1001;

    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $color-white;
    color: $color-mine-shaft;

    &__close {
        @include clickableSpace;

        z-index: 1;
        position: absolute;
        top: 24px;
        right: 60px;
        font-size: 32px;
    }

    &__container {
        column-gap: 0;
        align-content: stretch;
        height: 100%;
        padding-right: 60px;
        padding-left: 60px;
    }

    &__left,
    &__right {
        @include scrollContainer;

        padding-top: 60px;
        padding-bottom: 60px;
    }

    &__left {
        display: flex;
        grid-column: span 5;
        flex-direction: column;
        margin-right: 10px;
        margin-left: -60px;
        background-color: $color-wild-sand;
    }

    &__right {
        grid-column: span 7;
        padding-left: 50px;
        color: $color-cod-gray;
    }

    &__navItem {
        display: block;
        z-index: 1;
        position: relative;
        width: 100%;
        padding: 24px var(--container-p-x);
        color: $color-cod-gray !important;

        @include desktop {
            line-height: math.div(43, 36);

            &::before {
                display: block;
                z-index: -1;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                transform: scaleX(0);
                transform-origin: left center;
                background-color: $color-mercury;
                content: "";
                transition: transform $transition;
            }

            &:hover,
            &:global(.isActive) {
                &::before {
                    transform: none;
                }
            }
        }
    }

    &__navItemIcon {
        margin-left: 11px !important;
        color: $color-mine-shaft;
        font-size: 24px;
        transition: transform $transition;

        @include desktop {
            #{$p}__navItem:hover &,
            #{$p}__navItem:global(.isActive) & {
                transform: translateX(12px);
                color: $color-cod-gray;
            }
        }
    }

    &__socials {
        display: flex;
        column-gap: 40px;
        row-gap: 12px;
        flex-wrap: wrap;
        align-items: center;
        margin-top: auto;
        padding: 60px var(--container-p-x) 0;
        letter-spacing: 0;
    }

    &__tab {
        @include gridContainer;

        &_images {
            display: flex;
            min-height: 100%;
        }
    }

    &__images {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-auto-rows: minmax(384px, 1fr);
        width: 100%;
        gap: 12px;
    }

    &__image {
        position: relative;

        &:nth-child(1) {
            grid-row: span 2;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__col {
        display: flex;
        grid-column: span 6;
        flex-direction: column;
    }

    &__link {
        width: 100%;
        padding: 16px;

        &_sm {
            padding-top: 10px;
            padding-bottom: 10px;
            letter-spacing: 0;
        }

        &_sm + &:not(&_sm),
        #{$p}__dropdown + &:not(&_sm) {
            margin-top: 24px;
        }

        &_xs {
            padding-top: 12px;
            padding-bottom: 12px;
            color: $color-mine-shaft;
            letter-spacing: 0;
        }
    }

    &__linkIcon {
        margin-left: 9px !important;
        font-size: 12px;
    }

    &__dropdownList {
        display: flex;
        flex-direction: column;
    }
}
