@use "sass:math";

@import "@Assets/vars";
@import "@Assets/functions";
@import "@Assets/mixins";

.component {
    $p: &;

    :global {
        .os-scrollbar {
            padding: 4px;

            .os-scrollbar-track {
                border-radius: 0;
                background-color: $color-alto;
            }

            .os-scrollbar-handle {
                border: 0;
                border-radius: 0;
                background-color: $color-dusty-gray;
                cursor: pointer;
                transition: background-color $transition;

                &::before {
                    top: -4px;
                    right: -4px;
                    bottom: -4px;
                    left: -4px;
                }

                @include desktop {
                    &:hover {
                        background-color: $color-cod-gray;
                    }
                }
            }
        }

        .os-scrollbar-vertical {
            width: 10px;

            .os-scrollbar-handle {
                width: 100%;
            }
        }

        .os-scrollbar-horizontal {
            height: 10px;

            .os-scrollbar-handle {
                height: 100%;
            }
        }
    }
}
