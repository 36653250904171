@use "sass:math";

@import "@Assets/vars";
@import "@Assets/mixins";

.component {
    $p: &;

    position: relative;

    &__wrapper {
        position: relative;
    }

    &__field {
        display: flex;
        appearance: none;
        align-items: center;
        width: 100%;
        min-height: 40px;
        padding: 4px 0;
        border: 0;
        border-bottom: 1px solid $color-alto;
        border-radius: 0;
        outline: none;
        background: transparent;
        box-shadow: none;
        color: $color-cod-gray;
        font-weight: 400;
        font-size: 16px;
        line-height: math.div(19.2, 16);
        font-family: inherit;
        resize: none;
        transition: border-color $transition;

        &::placeholder {
            color: $color-dusty-gray;
            opacity: 1;
        }

        &::-webkit-search-decoration,
        &::-webkit-search-cancel-button,
        &::-webkit-search-results-button,
        &::-webkit-search-results-decoration {
            display: none;
        }

        &::-ms-clear {
            display: none;
        }

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            appearance: none;
            margin: 0;
        }

        &:hover,
        &:focus,
        &:not(:placeholder-shown):not(&_phone),
        &_phone[data-is-valid="true"] {
            border-color: $color-cod-gray;
        }

        &:disabled {
            cursor: not-allowed;
        }

        &_textarea {
            height: 116px;
        }

        &_phone {
            padding-left: 32px;
        }

        #{$p}:global(.isError) & {
            border-color: $color-red;
        }

        @include tablet {
            min-height: 38px;
        }

        @include tabletOnly {
            font-size: 14px;
            line-height: math.div(17, 14);
        }

        @include mobile {
            min-height: 36px;
            padding-top: 0;
            padding-bottom: 8px;
        }
    }

    &__icon {
        position: relative;
        flex-shrink: 0;
        font-size: 24px;

        &_angle-down {
            margin-right: 10px;
            font-size: 18px;

            #{$p}:global(.isDropdown) & {
                transform: scaleY(-1);
            }

            @include tablet {
                margin-right: 6px;
            }
        }

        &_calendar,
        &_lock {
            position: absolute;
            top: 50%;
            right: 0;
            margin-top: -12px;
            pointer-events: none;

            path {
                stroke-width: 1;
            }
        }
    }

    &__phone {
        font-size: inherit;
        font-family: inherit;
    }

    &__phone &__phoneDropdownButton {
        border: 0;
        border-radius: 0 !important;
        background-color: transparent !important;

        :global(.selected-flag) {
            width: 32px;
            padding-left: 0;
            border-radius: 0 !important;
            background-color: transparent !important;

            :global(.arrow) {
                transition: transform $transition;
            }
        }

        @include tablet {
            padding-bottom: 4px;
        }

        @include mobile {
            padding-bottom: 8px;
        }
    }

    &__phone &__phoneDropdown {
        @include scrollContainer;

        width: auto;
        min-width: 250px;
        max-width: 432px;

        margin: -1px 0 0;
        padding: 0 12px 12px;
        border-radius: 0;
        background-color: $color-white;
        box-shadow: 0 4px 30px rgba($color-black, 0.15);
        font-size: 16px;

        :global(.country) {
            padding: 6px 0;
            background-color: transparent !important;
            transition: color $transition;

            :global(.flag) {
                margin-right: 8px;
            }

            :global(.country-name) {
                margin-right: 8px;
            }

            :global(.dial-code) {
                color: $color-boulder;
            }

            &:global(.highlight .country-name) {
                color: $color-cod-gray;
                font-weight: 500;
            }

            @include desktop {
                &:hover {
                    color: $color-cod-gray;
                }
            }
        }

        :global(.divider) {
            margin: 6px 0;
            padding: 0;
        }

        :global(.no-entries-message) {
            padding: 6px 0;
        }
    }

    &__phone &__phoneDropdown &__phoneDropdownSearch {
        padding: 12px 0 6px;

        input {
            width: 100%;
            margin: 0;
            padding: 6px 10px;
            border: 1px solid $color-alto;
            border-radius: 0;
            color: $color-mine-shaft;
            font-size: 14px;
            line-height: math.div(18, 14);
            transition: border-color $transition;

            &::placeholder {
                color: $color-dusty-gray;
            }

            &::-webkit-search-decoration,
            &::-webkit-search-cancel-button,
            &::-webkit-search-results-button,
            &::-webkit-search-results-decoration {
                display: none;
            }

            &::-ms-clear {
                display: none;
            }

            &:focus-within {
                border-color: $color-dusty-gray;
            }

            @include desktop {
                &:hover:not(:focus) {
                    border-color: $color-cod-gray;
                }
            }

            @include tablet {
                font-size: 12px;
                line-height: math.div(16, 12);
            }
        }
    }

    &__info {
        display: none;
        position: absolute;
        top: 100%;
        right: 0;
        margin-top: 2px;
        font-size: 12px;
        line-height: math.div(14.4, 12);
        text-align: right;
        cursor: pointer;

        #{$p}:global(.isInfo) & {
            display: block;
        }

        &._error {
            color: $color-red;
        }

        &._success {
            color: $color-green;
        }
    }
}
