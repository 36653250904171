@use "sass:math";

@import "@Assets/vars";
@import "@Assets/functions";
@import "@Assets/mixins";

.component {
    $p: &;

    position: relative;

    &__loader {
        display: flex;
        z-index: 1;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        align-items: center;
        justify-content: center;
    }

    &__content {
        display: flex;
        row-gap: 24px;
        flex-direction: column;
        transition: opacity $transition;

        #{$p}:global(.isLoading) & {
            opacity: 0.25;
            pointer-events: none;
        }

        @include tablet {
            row-gap: 16px;
        }
    }

    &__input {
        flex: 1;

        &_textarea {
            textarea {
                height: 68px;
            }
        }

        @include tablet {
            &_textarea {
                textarea {
                    height: 60px;
                }
            }
        }
    }

    &__buttonSubmit {
        width: 100%;
        margin-top: 28px;

        @include tablet {
            margin-top: 24px;
        }

        @include mobile {
            height: 48px;
        }
    }

    &__error {
        position: absolute;
        top: 100%;
        left: 0;
        margin-top: 8px;
        color: $color-red;
        font-size: 12px;
        line-height: math.div(14.4, 12);

        @include tablet {
            margin-top: 16px;
        }
    }
}
