@use "sass:math";

@import "@Assets/vars";
@import "@Assets/mixins";

.component {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    &__input {
        flex-grow: 1;
    }

    &__button {
        margin-left: 4px;

        @include tablet {
            margin-left: 4px;
        }

        @include mobile {
            margin-left: 8px;
        }
    }
}
