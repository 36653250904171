@use "sass:math";

@import "@Assets/vars";
@import "@Assets/mixins";

.component {
    $p: &;

    display: flex;
    flex-direction: column;
    align-items: center;

    &__caption {
        margin-top: 12px;
        color: $color-dusty-gray;

        @include tablet {
            max-width: 220px;
        }
    }

    &__button {
        min-width: 172px;
        margin-top: 40px;

        @include tablet {
            margin-top: 32px;
        }
    }
}
