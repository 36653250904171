@use "sass:math";

@import "@Assets/vars";
@import "@Assets/functions";
@import "@Assets/mixins";

.component {
    $p: &;

    display: flex;
    position: relative;
    column-gap: 40px;
    align-items: center;
    justify-content: space-between;

    @include tablet {
        column-gap: var(--container-p-x, 16px);
    }

    &__name {
        transition: color $transition;

        @include desktop {
            #{$p}:hover & {
                color: $color-boulder;
            }
        }

        @include mobile {
            font-size: 14px;
            line-height: 1;
        }
    }

    &__price {
        display: flex;
        column-gap: 8px;
        flex-shrink: 0;
        align-items: center;

        @include mobile {
            font-size: 16px;
            line-height: 1;
        }
    }

    &__priceOld {
        flex-shrink: 0;
        color: $color-dusty-gray;
        font-size: 14px;
        line-height: 1;
        text-decoration: line-through;
        text-decoration-thickness: 1px;

        @include mobile {
            font-size: 12px;
            line-height: 1;
        }
    }

    &__link {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}
