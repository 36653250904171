@use "sass:math";

@import "@Assets/vars";
@import "@Assets/functions";
@import "@Assets/mixins";

.component {
    $p: &;

    @include tablet {
        display: block;
        padding: 0;
        background-color: $color-white;
    }

    &__container {
        display: flex;

        @include tablet {
            display: block;
            max-width: none;
            margin: 0;
            background-color: transparent;
        }
    }

    &__image {
        position: relative;
        flex-shrink: 0;
        width: 302px;

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        @include tablet {
            width: 100%;
            height: 200px;
        }
    }

    &__content {
        margin: auto 0;
        padding: 60px 68px 53px 35px;

        @include tablet {
            padding: 32px var(--container-p-x);
        }

        @include mobile {
            padding-top: 16px;
            padding-bottom: 16px;
        }
    }

    &__title {
        color: $color-cod-gray;
    }

    &__text {
        max-width: 322px;
        margin-top: 12px;

        @include mobile {
            max-width: none;
        }
    }

    &__parts {
        display: flex;
        column-gap: 32px;
        justify-content: center;
        margin-top: 32px;

        @include mobile {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            margin-top: 20px;
            gap: 20px;
        }
    }

    &__partPrice {
        color: $color-cod-gray;
    }

    &__partCaption {
        margin-top: 4px;
        font-size: 12px;
        line-height: math.div(14, 12);
    }

    &__list {
        margin-top: 32px;

        @include mobile {
            margin-top: 20px;
        }
    }

    &__listItem {
        position: relative;
        margin-top: 4px;
        padding-left: 18px;

        &::before {
            display: block;
            position: absolute;
            top: 6px;
            left: 6px;
            width: 2px;
            height: 2px;
            border-radius: 50%;
            background-color: currentColor;
            content: "";
        }

        &:first-child {
            margin-top: 0;
        }
    }

    &__info {
        margin-top: 12px;
        color: $color-dusty-gray;
        font-size: 12px;
        line-height: math.div(14, 12);

        a {
            text-decoration: underline;
            text-decoration-thickness: 1px;
            text-decoration-skip-ink: none;
            text-underline-offset: 1px;

            @include desktop {
                &:hover {
                    color: $color-cod-gray;
                }
            }
        }
    }
}
