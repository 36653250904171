@use "sass:math";

@import "@Assets/vars";
@import "@Assets/mixins";

.component {
    $p: &;

    @include fixedFullWidth;
    z-index: 10000;

    bottom: 0;
    padding: 24px 0;
    background: $color-white;
    box-shadow: 0px -4px 30px 0px rgba($color-black, 0.08);

    @include tablet {
        padding-top: 12px;
        padding-bottom: 20px;
        box-shadow: 0px -4px 20px 0px rgba($color-black, 0.08);
    }

    &__container {
        @include desktop {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }

    &__text {
        color: $color-black;

        @include desktop {
            padding-right: 40px;
        }
    }

    &__button {
        flex-shrink: 0;
        min-width: 148px;

        @include tablet {
            width: auto;
            margin-top: 9px;
        }
    }
}
