@use "sass:math";

@import "@Assets/vars";
@import "@Assets/mixins";

.component {
    $p: &;
    display: flex;

    position: relative;
    align-items: center;
    justify-content: center;
    height: 56px;
    padding: 8px 39px;
    border: 1px solid $color-mine-shaft;
    color: $color-white;
    font-size: 16px;
    line-height: math.div(20, 16);
    letter-spacing: 0.096px;
    text-align: center;
    text-transform: uppercase;
    white-space: nowrap;
    transition:
        border-color $transition,
        color $transition,
        background-color $transition;

    &:disabled,
    &:global(.isDisabled) {
        border-color: $color-alto;
        background-color: $color-alto;
        color: $color-dusty-gray;
        cursor: not-allowed;
    }

    @include tablet {
        height: 48px;
        font-size: 14px;
        line-height: math.div(18, 14);
        letter-spacing: 0;
    }

    @include mobile {
        padding-right: 23px;
        padding-left: 23px;
    }

    &_md {
        height: 48px;

        @include tablet {
            height: 46px;
        }

        @include mobile {
            height: 40px;
        }
    }

    &_sm {
        height: 40px;

        @include mobile {
            height: 32px;
        }
    }

    &_primary {
        background-color: $color-mine-shaft !important;

        @include desktop {
            &:not(:disabled):not(:global(.isDisabled)):hover {
                border-color: $color-tundora2;
                background-color: $color-tundora2 !important;
            }
        }
    }

    &_light {
        border-color: $color-white;
        background-color: $color-white !important;
        color: $color-cod-gray;

        @include desktop {
            &:not(:disabled):not(:global(.isDisabled)):hover {
                border-color: $color-mercury;
                background-color: $color-mercury !important;
            }
        }
    }

    &_tDark {
        border-color: $color-dusty-gray;
        background-color: transparent !important;
        color: $color-cod-gray;

        &:disabled,
        &:global(.isDisabled) {
            border-color: $color-dusty-gray;
            color: $color-dusty-gray;
        }

        @include desktop {
            &:not(:disabled):not(:global(.isDisabled)):hover {
                border-color: $color-cod-gray;
            }
        }
    }

    &_tLight {
        border-color: $color-white;
        background-color: transparent !important;
        color: $color-white;

        &:disabled,
        &:global(.isDisabled) {
            border-color: $color-boulder;
            color: $color-boulder;
        }

        @include desktop {
            &:not(:disabled):not(:global(.isDisabled)):hover {
                border-color: $color-alto;
                color: $color-alto;
            }
        }
    }

    @include mobile {
        width: 100%;
    }
}
