@use "sass:math";

@import "@Assets/vars";
@import "@Assets/functions";
@import "@Assets/mixins";

.component {
    @include gridContainer;

    &__content {
        grid-column: span 6;
    }

    &__links {
        @include gridContainer($cols: 3);

        margin-top: 32px;
        letter-spacing: 0;
    }

    &__linksCol {
        display: flex;
        row-gap: 20px;
        flex-direction: column;
    }

    &__link {
        display: block;
        width: 100%;
    }

    &__linkIcon {
        top: 2px;
        margin-left: 12px !important;
        font-size: 12px;

        path {
            stroke-width: 1.5;
        }
    }

    &__sublinks {
        display: flex;
        row-gap: 16px;
        flex-direction: column;
        padding-top: 16px;
    }

    &__sublink {
        color: $color-mine-shaft;
        letter-spacing: 0;
    }

    &__cards {
        @include gridContainer($cols: 2);

        grid-column: span 6;
    }
}
