@use "sass:math";

@import "@Assets/vars";
@import "@Assets/functions";
@import "@Assets/mixins";

.component {
    $p: &;

    &__scrollbar {
        margin-bottom: -5px;
        padding-bottom: 5px;

        :global([data-overlayscrollbars-contents]) {
            display: flex;
            position: relative;
        }
    }

    &__table {
        display: grid;
        grid-template-columns: repeat(
            var(--cols-count, 6),
            minmax(min-content, max-content)
        );
        column-gap: 40px;
        justify-content: space-between;

        &:global(.isSingle) {
            grid-template-columns: auto 1fr;
        }

        @include desktop {
            line-height: math.div(22, 18);
        }

        @include mobile {
            column-gap: 24px;
            font-size: 14px;
            line-height: math.div(17, 14);
        }
    }

    &__cell {
        padding: 16px 0;
        text-align: center;

        &_first {
            position: sticky;
            left: 0;
            width: 170px;
            margin-right: -20px;
            padding-right: 20px;
            background: linear-gradient(
                to right,
                $color-white,
                $color-white 90%,
                rgba($color-white, 0)
            );
            text-align: left;
        }

        @include mobile {
            padding: 12px 0;

            &_first {
                width: auto;
                min-width: 55px;
                max-width: 93px;
                margin-right: -12px;

                &:not(:first-of-type) {
                    font-size: 12px;
                    line-height: math.div(14, 12);
                    letter-spacing: 0.072px;
                }
            }
        }
    }

    &__divider {
        display: block;
        grid-column: 1 / -1;
        height: 1px;
        background-color: $color-alto;

        &_first {
            background-color: $color-mine-shaft;
        }
    }

    &__footer {
        display: flex;
        column-gap: 12px;
        justify-content: space-between;
        margin-top: 8px;
    }

    &__caption {
        color: $color-dusty-gray;
    }

    &__buttonScroll {
        display: block;
    }

    &__promptIcon {
        margin-left: 8px !important;
        font-size: 14px;
    }
}
