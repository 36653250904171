@use "sass:math";

@import "@Assets/vars";
@import "@Assets/functions";
@import "@Assets/mixins";

.component {
    $p: &;

    &__table {
        color: $color-cod-gray;
    }

    &__text {
        margin-top: 24px;
        color: $color-cod-gray;

        @include mobile {
            line-height: math.div(15, 14);
        }
    }

    &__contacts {
        display: flex;
        column-gap: 16px;
        align-items: center;
        margin-top: 16px;
    }

    &__contactsItem {
        width: 31px;
        height: 31px;
        transition: opacity $transition;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }

        @include desktop {
            &:hover {
                opacity: 0.75;
            }
        }

        @include mobile {
            width: 30px;
            height: 30px;
        }
    }
}
