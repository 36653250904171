@use "sass:math";

@import "@Assets/vars";
@import "@Assets/functions";
@import "@Assets/mixins";

.component {
    $p: &;

    position: relative;
    color: $color-cod-gray;

    &::before {
        display: block;
        position: absolute;
        top: 50%;
        right: 40px;
        width: 1px;
        height: 24px;
        margin-top: -12px;
        background-color: $color-alto;
        content: "";
    }

    @include tablet {
        &::before {
            right: 64px;
        }
    }

    @include mobile {
        &::before {
            right: 48px;
        }
    }

    &__input {
        display: block;
        appearance: none;
        width: 100%;
        height: 40px;
        padding: 4px 80px 4px 8px;
        border: 0;
        border-radius: 0;
        outline: none;
        background: $color-white;
        box-shadow: none;
        color: $color-cod-gray;
        font-weight: 400;
        font-size: 16px;
        line-height: math.div(19, 16);
        font-family: inherit;
        resize: none;

        &::placeholder {
            color: $color-dusty-gray;
            opacity: 1;
        }

        &::-webkit-search-decoration,
        &::-webkit-search-cancel-button,
        &::-webkit-search-results-button,
        &::-webkit-search-results-decoration {
            display: none;
        }

        &::-ms-clear {
            display: none;
        }

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            appearance: none;
            margin: 0;
        }

        &:disabled {
            cursor: not-allowed;
        }

        @include tablet {
            padding-right: 120px;
            padding-left: 32px;
        }

        @include mobile {
            padding-right: 94px;
            padding-left: 16px;
        }
    }

    &__buttonClose,
    &__iconSearch {
        display: block;
        position: absolute;
        top: 50%;
        margin-top: -12px;
        font-size: 24px;
    }

    &__buttonClose {
        right: 48px;
        transition: color $transition;

        @include desktop {
            &:hover {
                color: $color-boulder;
            }
        }

        @include tablet {
            right: 72px;
        }

        @include mobile {
            right: 56px;
        }
    }

    &__iconSearch {
        right: 8px;
        pointer-events: none;

        @include tablet {
            right: 32px;
        }

        @include mobile {
            right: 16px;
        }
    }
}
