@use "sass:math";

@import "@Assets/vars";
@import "@Assets/functions";
@import "@Assets/mixins";

.component {
    $p: &;

    @include desktop {
        position: relative;
        overflow: hidden;
    }

    &__slider {
        @include desktop {
            width: 100%;
            height: 100%;
        }
    }

    &__slide {
        @include desktop {
            display: flex;
            position: relative;
        }
    }

    &__item {
        @include desktop {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__video {
        @include desktop {
            width: 100%;
            height: 100%;

            video {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }

    :global {
        .swiper-pagination-bullets {
            @include desktop {
                display: flex;
                z-index: 1;
                position: absolute;
                top: auto;
                right: 12px;
                bottom: 12px;
                left: 12px;
                column-gap: 2px;
                align-items: center;
                justify-content: space-between;
                width: auto;
            }
        }

        .swiper-pagination-bullets .swiper-pagination-bullet {
            @include desktop {
                display: block;
                position: relative;
                flex: 1;
                width: auto;
                height: 2px;
                margin: 0;
                border-radius: 0;
                background-color: $color-white;
                opacity: 0.4;
                transition: opacity $transition;

                &::before {
                    position: absolute;
                    top: -15px;
                    right: 0;
                    bottom: -15px;
                    left: 0;
                    content: "";
                }
            }
        }

        .swiper-pagination-bullets .swiper-pagination-bullet-active {
            @include desktop {
                opacity: 1;
            }
        }
    }
}
