@use "sass:math";

@import "@Assets/vars";
@import "@Assets/functions";
@import "@Assets/mixins";

.component {
    $p: &;

    @include tablet {
        align-items: flex-start;
        padding: 0;
        background-color: $color-white;
    }

    &__container {
        display: flex;
        flex-direction: column;
        padding: 32px;

        @include desktop {
            width: 507px;
        }

        @include tablet {
            margin: 0;
            padding: var(--container-p-x);
            background-color: transparent;
        }
    }

    &__title {
        padding-right: 40px;
        color: $color-cod-gray;
    }

    &__products {
        max-height: 202px;
        margin-top: 24px;
        margin-right: -18px;
        padding-right: 18px;

        @include mobile {
            margin-top: 12px;
            margin-right: -14px;
            padding-right: 14px;
        }
    }

    &__product {
        margin-bottom: 8px;
        padding-bottom: 8px;
        border-bottom: 1px solid $color-alto;

        &:last-child {
            margin-bottom: 0;
            border-bottom: 0;
        }
    }

    &__sum {
        margin-top: 16px;
    }

    &__form {
        margin-top: 32px;

        @include mobile {
            margin-top: 20px;
        }
    }

    &__link {
        align-self: center;
        margin-top: 24px;

        @include desktop {
            &:hover {
                color: $color-boulder;
            }
        }

        @include mobile {
            margin-top: 16px;
        }
    }

    &__linkIcon {
        font-size: 12px;

        path {
            stroke-width: 1;
        }
    }
}
